@font-face {
  font-family: kbbody;
  src: url(https://aegwebprod.blob.core.windows.net/content/kane-brown/fonts/AkzidenzGrotesk-LightCond.otf);
}

body {
  background-color: #151a1d;
  color: #f8f9fa;
  font-size: 16px;
  font-family: kbbody, serif;
  margin: 0;
  padding: 0;
}

#loginPage {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    height: auto;
    width: 350px;
    max-width: 100%;
  }

  h4 {
    text-align: center;
    margin: 2rem 0;
    font-weight: 900;
    font-size: 29.5px;
    text-transform: uppercase;
  }

}

#galleryPage {
  padding-bottom: 50px;
}

.btn-toolbar {
  justify-content: center;
  button.btn {
    align-self: center;
    font-size: 28px;
    line-height: 28px;
  }
}

input:focus::placeholder {
  color: transparent;
}

.react-toast-notifications__toast__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px !important;
  letter-spacing: 1px;
  line-height: 20px !important;
}

.react-toast-notifications__toast__dismiss-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-toast-notifications__toast__icon-wrapper {
  padding: 0 !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}